import { render, staticRenderFns } from "./SectionHeading.vue?vue&type=template&id=6a699514&"
import script from "./SectionHeading.vue?vue&type=script&lang=js&"
export * from "./SectionHeading.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarkdownBlock: require('/builds/devstark/itg/itg-custom-illustrations/front/components/MarkdownBlock.vue').default,UiBtn: require('/builds/devstark/itg/itg-custom-illustrations/front/components/UiBtn.vue').default})
